import { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import _debounce from 'lodash/debounce';
import { Row, Col } from 'reactstrap';
import _get from 'lodash/get';
import { useSelector } from 'react-redux';

import NumberInput from 'common/components/form/inputs/NumberInput';
import CircledButton from 'common/components/buttons/CircledButton';
import CollapseBox from 'common/components/collapse/CollapseBox';
// import Rob from './Rob';

import { numberToStr, strToNumber } from 'common/utils/numbers';

import { useActions } from 'utils/hooks';
import * as maintenanceActions from 'common/components/jobs/maintenance/store/actions';
import { selectIsJobLocked } from 'common/components/jobs/_base/store/selectors';
import {
  selectMaintenanceDetaisVesselSystemAssignment,
  selectShowMaintenanceConsumedParts,
  selectShowMaintenanceRequiredParts,
  isSpacePartsCollapsed,
  selectIsUnscheduledJob
} from 'common/components/pms/jobs/store/selectors';
import SparePartsSelector from 'common/components/selectors/SparePartsSelector';

const SpareParts = ({ type, className, jobId = null }) => {
  const consumedParts = useSelector(selectShowMaintenanceConsumedParts);
  const requiredParts = useSelector(selectShowMaintenanceRequiredParts);

  const isRequiredAsPerManufacturerView = type === 'required';
  const isConsumedFromInventoryView = type === 'consumed';

  const data = isRequiredAsPerManufacturerView ? requiredParts : consumedParts;
  const dataIdentifier = `${type}-spare-part`;

  const isOnBoard = useSelector(state => state.isOnBoard);
  const isUnscheduled = useSelector(selectIsUnscheduledJob);
  const isJobLocked = useSelector(selectIsJobLocked) || (!isOnBoard && isConsumedFromInventoryView);

  const canUpdateRequiredSpareParts =
    !isJobLocked && !isOnBoard && isUnscheduled && isRequiredAsPerManufacturerView; // When in office & isUnschedule the required spare parts can be updated from Office

  const isCollapsed = useSelector(isSpacePartsCollapsed);

  const [
    setMaintenanceSparePart,
    removeMaintenanceSparePart,
    addMaintenanceSparePart,
    updateMaintenance,
    initializeConsumedSpareParts,
    setSparePartsCollapsed
  ] = useActions([
    maintenanceActions.setMaintenanceSparePart,
    maintenanceActions.removeMaintenanceSparePart,
    maintenanceActions.addMaintenanceSparePart,
    maintenanceActions.updateMaintenance,
    maintenanceActions.initializeConsumedSpareParts,
    maintenanceActions.setSparePartsCollapsed
  ]);

  const systemId = useSelector(selectMaintenanceDetaisVesselSystemAssignment)?.vessel_system_id;

  useEffect(() => {
    if (isConsumedFromInventoryView && !consumedParts?.length && requiredParts?.length) {
      const requiredToConsumedArray =
        requiredParts
          ?.filter(p => p.spare_part_id)
          ?.map(p => ({
            quantity: null,
            spare_part: p.spare_part,
            spare_part_id: p.spare_part_id,
            isRequired: true
          })) || [];

      initializeConsumedSpareParts(requiredToConsumedArray);
    }
  }, [consumedParts?.length, requiredParts?.length, canUpdateRequiredSpareParts]);

  useEffect(() => {
    if (isConsumedFromInventoryView && consumedParts?.length && requiredParts?.length) {
      const requiredToConsumedArray =
        consumedParts?.map(e =>
          requiredParts.find(required => required.spare_part_id === e.spare_part_id)
            ? {
                ...e,
                isRequired: true
              }
            : e
        ) || [];

      initializeConsumedSpareParts(requiredToConsumedArray);
    }
  }, [isConsumedFromInventoryView, requiredParts]);

  const updateSpareParts = useCallback(
    parts => updateMaintenance({ spare_parts: parts }),
    [updateMaintenance]
  );

  const debouncedUpdate = useCallback(_debounce(updateSpareParts, 600), [updateSpareParts]);

  const triggerChange = useMemo(() => {
    const result = data.map(el => el?.spare_part?.id).filter(el => el);

    return { exclude_ids: result || [], systemId };
  }, [data?.length, systemId]);

  const onSelectSparePart = (opt, i) => {
    setMaintenanceSparePart({ name: 'spare_part', value: opt, index: i }, type);

    if (opt) {
      const newParts = data
        .map((el, ind) => {
          if (ind === i) {
            return {
              ...el,
              spare_part: opt,
              spare_part_id: opt ? opt.id : null
            };
          } else {
            return {
              ...el,
              spare_part_id: el.spare_part?.id ? el.spare_part.id : null
            };
          }
        })
        .filter(el => el.spare_part || el.quantity);
      debouncedUpdate(newParts);
    }
  };

  const onUpdateSparePartInput = (e, i) => {
    setMaintenanceSparePart(
      { name: 'quantity', value: strToNumber(e.target.value), index: i },
      type
    );

    if (e.target.value) {
      const newParts = data
        .map((el, ind) => {
          if (ind === i) {
            return {
              ...el,
              quantity: strToNumber(e.target.value),
              spare_part_id: el.spare_part?.id ? el.spare_part.id : null
            };
          } else {
            return {
              ...el,
              spare_part_id: el.spare_part?.id ? el.spare_part.id : null
            };
          }
        })
        .filter(el => el.spare_part || el.quantity);

      debouncedUpdate(newParts);
    }
  };

  // const onRobChange = (e, i) => {
  //   setMaintenanceSparePart({ name: 'rob', value: strToNumber(e.target.value), index: i }, type);
  //   if (e.target.value) {
  //     const newParts = data
  //       .map((el, ind) => {
  //         if (ind === i) {
  //           return {
  //             ...el,
  //             rob: strToNumber(e.target.value),
  //             spare_part_id: el.spare_part.id ? el.spare_part.id : null
  //           };
  //         } else {
  //           return {
  //             ...el,
  //             spare_part_id: el.spare_part.id ? el.spare_part.id : null
  //           };
  //         }
  //       })
  //       .filter(el => el.spare_part || el.quantity || el.rob);

  //     debouncedUpdate(newParts);
  //   }
  // };

  const onDeleteSparePart = index => {
    const itemToDelete = data[index];

    const hasValues = itemToDelete.spare_part?.id;
    if (hasValues) {
      const newParts = data
        .map(el => ({
          ...el,
          spare_part_id: el.spare_part?.id ? el.spare_part.id : null
        }))
        .filter((el, ind) => ind !== index && (el.spare_part || el.quantity));
      debouncedUpdate(newParts);
    }

    removeMaintenanceSparePart(index, type);
  };

  const onAddSparePart = () => {
    addMaintenanceSparePart(type);
  };

  return (
    <div className={`unscheduled-spare-parts ${className || ''}`}>
      <CollapseBox
        header={<div className="form-label">SPARE PARTS</div>}
        expandButton={false}
        hideExpandButton={true}
        headerClassName="px-0 mt-1"
        triggerOpen={isCollapsed}
        onOpen={setSparePartsCollapsed}
        onClose={setSparePartsCollapsed}
        bodyInnerClassName="pt-0 px-0 pb-0"
        innerExpandButton
      >
        <div className="unscheduled-spare-parts__container">
          <Row className="text-primary fw-bold fs-10 cmb-4">
            <Col xs={7} className="fs-10">
              {isRequiredAsPerManufacturerView ? 'REQUIRED' : 'CONSUMED'}{' '}
              <span className="text-violet">
                {isRequiredAsPerManufacturerView ? 'AS PER MANUFACTURER' : 'FROM INVENTORY'}
              </span>
            </Col>
            <Col xs={2}>QUANTITY</Col>
            {/* {jobId ? (
              <Col xs={3} className="fs-10">
                ROB
                <span className="text-violet">&nbsp;after job completion</span>
              </Col>
            ) : null} */}
          </Row>
          {data.map((part, i) => (
            <Row key={i.toString()}>
              <Col xs={7}>
                <SparePartsSelector
                  className="mb-0 fs-12"
                  placeholder="Select spare part"
                  value={_get(part, 'spare_part')}
                  error={null}
                  invisible
                  disabled={
                    (isJobLocked || isRequiredAsPerManufacturerView) && !canUpdateRequiredSpareParts
                  }
                  listParams={{
                    exclude_ids: triggerChange.exclude_ids,
                    vessel_system_id: triggerChange.systemId,
                    with_related: true
                  }}
                  onChange={opt => onSelectSparePart(opt, i)}
                  noOptionsMessage={({ inputValue }) =>
                    !inputValue.length ? `Search for parts` : `No part found`
                  }
                  dataCy={`${dataIdentifier}-selector`}
                />
              </Col>
              <Col className="d-flex align-items-center justify-content-between">
                <Row>
                  <Col xs={`${jobId ? 10 : 12}`}>
                    <NumberInput
                      placeholder="Add no."
                      disabled={
                        (!part.spare_part?.id || isJobLocked || isRequiredAsPerManufacturerView) &&
                        !canUpdateRequiredSpareParts
                      }
                      className="mb-0"
                      onChange={e => onUpdateSparePartInput(e, i)}
                      value={part.quantity ? numberToStr(part.quantity) : null}
                      dataCy={`${dataIdentifier}-quantity`}
                      invisible
                      allowNegative={false}
                      decimalScale={0}
                    />
                  </Col>
                </Row>
              </Col>

              {/* <Col xs={3} className="d-flex align-items-center justify-content-between">
                  <Row>
                    <Col xs={8}>
                      <NumberInput
                        placeholder="Add no."
                        disabled={!part.spare_part?.id || isJobLocked || isRequiredAsPerManufacturerView}
                        className="mb-0"
                        onChange={e => onRobChange(e, i)}
                        value={part.rob ? numberToStr(part.rob) : null}
                        dataCy={`${dataIdentifier}-rob`}
                        invisible
                      />
                    </Col>
                    <Col xs={3} className="d-flex align-items-center justify-content-end">
                      <Rob part={part} />
                    </Col>
                  </Row>
                </Col> */}

              {canUpdateRequiredSpareParts ||
              (!isJobLocked &&
                isConsumedFromInventoryView &&
                !(part.isRequired || part.is_required)) ? (
                <Col xs="auto">
                  <CircledButton
                    onClick={() => onDeleteSparePart(i)}
                    svgStyle={{ height: 5, width: 5 }}
                    className="spare-part-remove-btn ms-auto"
                    dataCy={`${dataIdentifier}-remove-btn`}
                    type={'remove'}
                  />
                </Col>
              ) : null}
            </Row>
          ))}

          {canUpdateRequiredSpareParts || (!isJobLocked && isConsumedFromInventoryView) ? (
            <CircledButton
              onClick={onAddSparePart}
              disabled={!systemId || data?.some(e => !e.spare_part)}
              type={'add'}
              label="Add spare part"
              svgStyle={{ width: 8, height: 8 }}
              className="text-primary fw-bold fs-12 mt-1"
              dataCy={`${dataIdentifier}-add-btn`}
            />
          ) : null}
        </div>
      </CollapseBox>
    </div>
  );
};

SpareParts.propTypes = {
  type: PropTypes.oneOf(['required', 'consumed']).isRequired,
  className: PropTypes.string,
  jobId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default SpareParts;
