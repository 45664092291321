import { EntityLog, LOGS_ENTITY_TYPES } from '@/common/types/logs';
import { ReactNode } from 'react';
import { capitalizeFirst } from '@/common/utils/texts';

const parsingActionName = (serviceAction: string) => {
  switch (serviceAction) {
    case 'created_one_or_bulk':
      return 'created';
    case 'triggered_review_process':
      return 'triggered approval process';
    default:
      return serviceAction;
  }
};

const parsingEntityName = (serviceAction: string) => {
  switch (serviceAction) {
    case 'purchasing_requisition_supplier':
      return 'purchasing';
    case 'event_m_accounts':
      return 'Events - Accounts';
    case 'crew_medical_case_expenses':
      return 'Events - Crew Medical Cases';
    case 'ticket_offer':
      return 'ticketing';
    case 'port_da_case_agent':
      return 'DA - Agents';
    case 'port_da_case_agent_item':
      return 'DA - Direct Expenses';
    default:
      return serviceAction;
  }
};

export const constructSentence = (entityLog: EntityLog): ReactNode => {
  const { service_category, service_action, service_subcategory, created_by } = entityLog;
  const serviceCategoryFormatted = service_category.replaceAll('_', ' ');
  const serviceActionFormatted = parsingActionName(service_action).replaceAll('_', ' ');
  const serviceEntities = entityLog?.entities || [];

  if (service_action === 'status_updated') {
    return (
      <span>
        {created_by.full_name} <strong>updated the status</strong> of the {serviceCategoryFormatted}
      </span>
    );
  } else if (service_action === 'created_subsystem') {
    return (
      <span>
        {created_by.full_name} <strong>created</strong> a subsystem
      </span>
    );
  } else if (
    service_category === 'job_trigger_prototypes' &&
    (service_action === 'created' || service_action === 'updated')
  ) {
    return (
      <span>
        {created_by.full_name} <strong>{serviceActionFormatted}</strong> a job
      </span>
    );
  } else if (
    (service_action === 'created' || service_action === 'updated') &&
    service_category === 'spare_part'
  ) {
    return (
      <span>
        {created_by.full_name} <strong>{serviceActionFormatted}</strong> a{' '}
        {serviceCategoryFormatted}
      </span>
    );
  } else if (service_category === LOGS_ENTITY_TYPES.party) {
    return (
      <span>
        {created_by.full_name} <strong>{serviceActionFormatted}</strong> the {service_subcategory}
      </span>
    );
  } else if (service_category === LOGS_ENTITY_TYPES.invoice && service_action === 'linked_cases') {
    const entity = serviceEntities?.[0];
    const caseName = parsingEntityName(entity?.entity_type);
    return (
      <span>
        {created_by.full_name} <strong>linked {caseName} cases</strong>.
      </span>
    );
  } else if (
    service_category === LOGS_ENTITY_TYPES.invoice &&
    service_action === 'sent_to_accounting'
  ) {
    return (
      <span>
        {created_by.full_name} <strong>sent the invoice to accounting</strong>.
      </span>
    );
  } else if (
    service_category === LOGS_ENTITY_TYPES.invoice &&
    (service_action === 'reviewed' || service_action === 'unreviewed')
  ) {
    const serviceAction = service_action === 'reviewed' ? 'marked' : 'unmarked';

    return (
      <span>
        {created_by.full_name} <strong>{serviceAction}</strong> the invoice as good for payment.
      </span>
    );
  } else if (service_category === LOGS_ENTITY_TYPES.event && service_subcategory === 'module') {
    const serviceAction = service_action === 'created' ? 'added' : service_action;

    return (
      <span>
        {created_by.full_name} <strong>{serviceAction}</strong> {service_subcategory}{' '}
      </span>
    );
  } else if (service_action === 'triggered_review_process') {
    return (
      <span>
        {created_by.full_name} <strong>{serviceActionFormatted}</strong>
      </span>
    );
  } else if (
    service_action === 'for_correction' &&
    service_subcategory === LOGS_ENTITY_TYPES.mga_period
  ) {
    return (
      <span>
        {created_by.full_name} sent period <strong>{serviceActionFormatted}</strong>
      </span>
    );
  } else if (service_subcategory === LOGS_ENTITY_TYPES.mga_period) {
    return (
      <span>
        {created_by.full_name} <strong>{serviceActionFormatted}</strong> the period.
      </span>
    );
  } else if (service_subcategory === LOGS_ENTITY_TYPES.mga_action) {
    return (
      <span>
        {created_by.full_name} <strong>{serviceActionFormatted}</strong> the action.
      </span>
    );
  } else if (service_category === 'vessel' && service_subcategory === 'document') {
    const vesselDocumentName =
      entityLog.entity_meta?.name ||
      `${capitalizeFirst(serviceCategoryFormatted)} ${capitalizeFirst(service_subcategory)}`;

    return (
      <span>
        {created_by.full_name} <strong>{serviceActionFormatted}</strong> the {vesselDocumentName}.
      </span>
    );
  } else if (service_action === 'excel_exported') {
    return (
      <span>
        {created_by.full_name} <strong>exported Excel</strong> file on{' '}
        {capitalizeFirst(serviceCategoryFormatted)}.
      </span>
    );
  } else {
    return (
      <span>
        {created_by.full_name} <strong>{serviceActionFormatted}</strong> the{' '}
        {serviceCategoryFormatted}
      </span>
    );
  }
};
