export const shouldShowCloseConfirmation = (
  isDrawerClosing: boolean,
  isOnboard: boolean,
  statusProgress: string,
  hasPreviousStatus: boolean
) => {
  return (
    isDrawerClosing &&
    !isOnboard &&
    statusProgress &&
    (statusProgress === 'for-review' || !hasPreviousStatus)
  );
};
