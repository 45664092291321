import PersonSelector from 'common/components/selectors/PersonSelector';
import purchasingIcon from '@/common/assets/svg/common/purchasing.svg';
import ImportanceSelector from 'common/components/selectors/ImportanceSelector';
import PurchasingCategorySelector from 'common/components/selectors/PurchasingCategorySelector';

import TopFilters from './TopFilters';

export const getColumns = () => [
  {
    header: 'P.',
    filterLabel: 'Priority',
    key: 'priority',
    type: 'collection',
    sort: true,
    minWidth: 80,
    maxWidth: 80,
    canFilter: false
  },
  {
    filterLabel: 'Priority',
    key: 'priority_id',
    type: 'collection',
    component: ImportanceSelector,
    componentRest: { isMulti: true, isPurchasing: true },
    hidden: true
  },
  {
    header: 'NO.',
    key: 'code',
    sort: true,
    width: 2
  },
  {
    header: 'DESCRIPTION',
    key: 'description',
    sort: true,
    width: 3
  },
  {
    header: 'Category',
    key: 'category_id',
    type: 'collection',
    component: PurchasingCategorySelector,
    componentRest: { isMulti: true },
    width: 3
  },
  {
    header: 'Submitted At',
    key: 'submitted_at',
    type: 'date',
    sort: true
  },
  {
    header: 'STATUS',
    key: 'onboard_status',
    type: 'collection',
    sort: true,
    canFilter: false
  },
  {
    header: '',
    key: 'items',
    canFilter: false
  },
  {
    header: 'Created by',
    key: 'created_by_id',
    type: 'collection',
    hidden: true,
    sort: false,
    component: PersonSelector,
    canFilter: true,
    componentRest: { isMulti: true, listParams: { can_login: true } }
  },
  {
    header: 'Actions',
    key: 'actions',
    field: 'actions'
  }
];

const getTopFilters = () => [
  {
    name: 'onboard_status',
    operation: '=',
    value: null
  }
];

const config = {
  getColumns,
  getTopFilters,
  topFiltersComponent: TopFilters,
  icon: purchasingIcon,
  isModular: false,
  hideTableFilters: false,
  pageTitleComponent: null,
  name: 'Purchasing Requisitions',
  entity: 'purchasing-requisitions'
};

export default config;
