import { useMemo } from 'react';
import PropTypes from 'prop-types';
import SvgRender from 'common/components/general/SvgRender';

import draft from 'common/assets/svg/common/draft.svg';
import rfq from 'common/assets/svg/actions/send.svg';
import qtn from 'common/assets/svg/socials/email.svg';
import pr from 'common/assets/svg/common/purchasing.svg';
import po from 'common/assets/svg/common/box.svg';
import dlv from 'common/assets/svg/actions/to-be-delivered.svg';
import ev from 'common/assets/svg/common/star.svg';
import inv from 'common/assets/svg/common/dollar.svg';
import { selectIsTemplateRequisition } from '../store/selectors';
import { selectRequisitionVessel } from 'common/components/purchasing/requisition/store/selectors';
import { useSelector } from 'react-redux';
import { getSupplierStatusExplanatoryText } from 'common/components/purchasing/requisition/categories/items/helpers';
import { selectListDefaultOptions } from 'store/lists/selectors';
import Tooltip from 'common/components/general/Tooltip';
import useTooltipID from 'common/utils/hooks/useTooltipID';

export const icons = {
  draft,
  rfq,
  qtn,
  pr,
  po,
  dlv,
  ev,
  comp: ev,
  inv
};

export const colors = {
  draft: 'violet',
  rqn: 'violet',
  rfq: 'violet',
  qtn: 'orange',
  pr: 'primary',
  po: 'purple',
  dlv: 'salmon-pink',
  ev: 'green',
  comp: 'green',
  inv: 'pacific-blue'
};

const getSvgClassNameBasedOnStatus = (statusStyle, status) => {
  switch (statusStyle) {
    case 'filled':
      return 'text-white';
    case 'disabled':
      return 'text-gray-400';
    default:
      return `text-${colors[status?.label]}`;
  }
};

const getWrapperClassNameBasedOnStatus = (statusStyle, status) => {
  switch (statusStyle) {
    case 'filled':
      return `bg-${colors[status?.label]} border border-${colors[status?.label]} text-white cpx-6`;
    case 'bordered':
      return `bg-white border border-${colors[status?.label]} text-${colors[status?.label]} cpx-6`;
    default:
      return 'text-gray-400 border border-gray-400 cpx-6';
  }
};

const RequisitionStatus = ({
  status,
  statusStyle,
  isLast,
  isBullet = false,
  hasTooltip = false
}) => {
  const statusesToHideWhenTemplate = ['ev', 'comp', 'inv'];
  const isTemplate = useSelector(selectIsTemplateRequisition);
  const statuses = useSelector(state =>
    selectListDefaultOptions(state, 'purchasing-requisition-statuses')
  );
  const requisitionVessel = useSelector(selectRequisitionVessel);

  const fullObjectStatus = useMemo(
    () => statuses?.find(s => s.id === status?.id),
    [statuses, status]
  );
  const hideLastDash = status.label === 'dlv' && isTemplate;
  const { avoidRender, tooltipID } = useTooltipID(status.label);

  if ((statusesToHideWhenTemplate.includes(status.label) && isTemplate) || avoidRender) return null;

  return status ? (
    isBullet ? (
      <>
        <div
          id={tooltipID}
          className={`d-inline-flex align-items-center text-${colors[status?.label] || 'primary'}`}
        >
          <div
            className={`simple-dot max-height-8 max-width-8 bg-${
              colors[status?.label] || 'primary'
            } cme-12`}
          ></div>
          <span
            className={`lh-1 fw-medium text-nowrap ${
              status?.label === 'draft' ? 'text-capitalize' : 'text-uppercase'
            }`}
          >
            {status?.label}
          </span>
        </div>
        {hasTooltip ? (
          <Tooltip target={tooltipID} innerClassName="min-width-fit max-width-fit" placement="left">
            {getSupplierStatusExplanatoryText(statuses, fullObjectStatus, !!requisitionVessel?.id)}
          </Tooltip>
        ) : null}
      </>
    ) : (
      <div className="d-flex align-items-center">
        <div
          className={`status-box d-inline-flex align-items-center cpt-4 cpb-4 fs-12 
      ${getWrapperClassNameBasedOnStatus(statusStyle, status)}`}
        >
          <SvgRender
            src={icons[status?.label]}
            style={{ width: 13, height: 13 }}
            className={`cme-4 ${getSvgClassNameBasedOnStatus(statusStyle, status)}`}
          />
          <span className="lh-1 fw-medium fs-12 text-nowrap">
            {status?.label === 'comp'
              ? 'Completed'
              : status?.label === 'ev'
              ? 'For Evaluation'
              : status?.label === 'inv'
              ? 'Invoicing'
              : status?.label.toUpperCase()}
          </span>
        </div>
        {!isLast && !hideLastDash ? <div className="status-box__separator" /> : null}
      </div>
    )
  ) : null;
};

RequisitionStatus.propTypes = {
  status: PropTypes.shape({
    id: PropTypes.number,
    label: PropTypes.string
  }),
  statusStyle: PropTypes.string,
  isLast: PropTypes.bool,
  isBullet: PropTypes.bool,
  hasTooltip: PropTypes.bool
};

export default RequisitionStatus;
