import { useState } from 'react';

import AsyncSelector from 'common/components/selectors/AsyncSelector';
import SelectSearchIndicator from 'common/components/form/helpers/SelectSearchIndicator';
import { numberToStr } from 'common/utils/numbers';

import {
  selectNumberOfCategoryItems,
  selectOnBoardStatus,
  selectRequisitionVessel
} from 'common/components/purchasing/requisition/store/selectors';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { addCategoryItem } from 'common/components/purchasing/requisition/store/actions';

import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';
import { components } from 'react-select';
import { Row, Col, Button } from 'reactstrap';
import { showSparePartsNumber } from 'common/utils/form/display';

import _debounce from 'lodash/debounce';
import AddFromListButton from './AddFromListButton';
import add from 'common/assets/svg/actions/add.svg';
import SvgRender from 'common/components/general/SvgRender';
import { selectListOptionsFromStore } from 'store/lists/selectors';
import { constructOutOfListItemId } from 'common/components/purchasing/requisition/categories/items/helpers';
import ColorViewer from 'common/components/general/ColorViewer';
import { selectCategoryItemIds } from '@/common/components/purchasing/requisition/store/selectors-ts.ts';
import styled from '@emotion/styled';

const ItemSelector = ({ categoryID, isPms }) => {
  const [outOfListItem, setOutOfListItem] = useState(null);
  const dispatch = useAppDispatch();
  const categoryItemsLength = useAppSelector(state =>
    selectNumberOfCategoryItems(state, categoryID)
  );

  const isOnBoard = useAppSelector(state => state.isOnBoard);
  const onBoardStatus = useAppSelector(selectOnBoardStatus);
  const vessel = useAppSelector(selectRequisitionVessel);

  const units = useAppSelector(state => selectListOptionsFromStore(state, 'purchasing-units'));

  const categorySelectedItemIds = useAppSelector(state => selectCategoryItemIds(state, categoryID));

  const pieceUnit = units?.find(u => u.label === 'piece');

  const fetchSearchResults = async search => {
    const items = await getAsyncOptions(search, isPms ? 'spare-parts' : 'stores', {
      purchasing_category_id: categoryID,
      type: isPms ? 'spare_part' : 'store',
      vessel_id: isPms && !isOnBoard ? vessel?.id : undefined
    });

    if (!items?.length) {
      setOutOfListItem(search);
    } else {
      setOutOfListItem(null);
    }

    return items;
  };

  const debouncedRemoveButton = _debounce(() => {
    setOutOfListItem(null);
  }, 300);

  if (isOnBoard && onBoardStatus !== 'draft') return null;

  return (
    <ItemSelectorContainer>
      <Row
        data-index={categoryItemsLength + 1}
        className="align-items-center cmt-2 bg-transparent purchasing-requisition__item-selector-container g-0 ps-1"
      >
        <Col className="rounded-lg bg-white cps-4 cpe-4" xs="auto">
          <AsyncSelector
            className="mb-0 purchasing-requisition__gray-selector purchasing-requisition__item-selector white-bg"
            onChange={item =>
              item &&
              dispatch(
                addCategoryItem({
                  item: { ...item, purchasing_unit: !isPms ? item.purchasing_unit : pieceUnit },
                  categoryID,
                  type: isPms ? 'spare_part' : 'store',
                  requested_packaging_id: !isPms ? item.purchasing_unit?.id : pieceUnit?.id
                })
              )
            }
            type={isPms ? 'spare-parts' : 'stores'}
            placeholder="Select an item to add in your list by 6-digit code or description"
            gray={true}
            menuPortalTarget={document.body}
            components={{ DropdownIndicator: SelectSearchIndicator, Option, Menu }}
            getOptionValue={option => option.id}
            getOptionLabel={option => option.description}
            loadOptions={search => fetchSearchResults(search)}
            defaultOptions={() =>
              getInitialAsyncValues(isPms ? 'spare-parts' : 'stores', null, null, {
                purchasing_category_id: categoryID,
                type: isPms ? 'spare_part' : 'store',
                vessel_id: isPms ? vessel?.id : undefined
              })
            }
            filterOption={option => !categorySelectedItemIds.includes(option.value)}
            onMenuClose={debouncedRemoveButton}
            defaultOptionsTriggerChange={vessel?.id}
          />
        </Col>
        {isOnBoard && outOfListItem && (
          <Col xs="auto" className="bg-white fs-14">
            <Button
              className="d-flex align-items-center justify-content-center text-white bg-primary cpt-6 cpb-6 px-1"
              type="button"
              onClick={() =>
                dispatch(
                  addCategoryItem({
                    item: {
                      id: constructOutOfListItemId(outOfListItem, isPms),
                      unlisted_description: outOfListItem,
                      is_out_of_the_list: true
                    },
                    categoryID
                  })
                )
              }
            >
              <SvgRender src={add} style={{ width: 12, height: 12 }} />
              <span className="fs-12 text-nowrap ms-1 fw-medium">Add out of the list item</span>
            </Button>
          </Col>
        )}

        <Col className="bg-transparent" xs="auto">
          <AddFromListButton categoryID={categoryID} isPms={isPms} />
        </Col>
      </Row>
    </ItemSelectorContainer>
  );
};

export const Option = ({ children, ...props }) => {
  return (
    <components.Option {...props}>
      <div className="d-flex w-100p">
        {props.data?.store ? (
          <>
            <strong className="cpe-6">{props.data.store.code}</strong>
            {props.data.store.description} | {props.data.remaining_quantity} @ $
            {numberToStr(props.data.price_per_unit, 2, 2)}{' '}
          </>
        ) : (
          <>
            {showSparePartsNumber(props.data.drawing_no) ? (
              <strong>{props.data.drawing_no} - </strong>
            ) : null}
            {showSparePartsNumber(props.data.code) ? <strong>{props.data.code} - </strong> : null}
            {props.data.description}
          </>
        )}
        {props.data?.color_name ? (
          <span className="text-violet">&nbsp;-&nbsp;{props.data.color_name}</span>
        ) : null}
        <div className="d-flex ms-auto">
          {props.data?.color_hex_code ? (
            <ColorViewer color={`#${props.data.color_hex_code}`} className={'ms-auto'} hideLabel />
          ) : null}
        </div>
      </div>
    </components.Option>
  );
};

const Menu = ({ children, ...props }) => {
  return (
    <components.Menu className="purchasing-requisition__item-selector" {...props}>
      {children}
    </components.Menu>
  );
};

const ItemSelectorContainer = styled.div`
  width: max-content;
  position: sticky;
  left: 0.25rem;
`;

export default ItemSelector;
