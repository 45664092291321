import eventsIcon from 'common/assets/svg/common/events.svg';

import vesselIcon from 'common/assets/svg/common/vessels.svg';
import companyIcon from 'common/assets/svg/common/company.svg';
import crewIcon from 'common/assets/svg/common/crew_planning.svg';

import { stringifyObj } from 'utils/urls';
import paths from 'routing/routes/_paths';
import config from '@/common/components/shared-table-configs';

const columnsToExcludeFromCompleted = ['days_left', 'ignore', 'actions'];

export const getCompletedColumns = () => [
  ...config['form_obligations']
    .getColumns()
    .filter(col => !columnsToExcludeFromCompleted.includes(col.key)),
  {
    header: 'Form Date',
    key: 'form_date',
    data_key: 'form_submission.submission_date',
    type: 'date',
    minWidth: 120,
    maxWidth: 120,
    sort: true,
    canFilter: false
  },
  {
    header: 'Actions',
    key: 'actions',
    field: 'actions'
  }
];

export const getFormNameIcon = type => {
  switch (type) {
    case 'event':
      return eventsIcon;
    case 'crew_rotation':
      return crewIcon;

    default:
      return null;
  }
};

export const getTriggerEntityUrl = data => {
  const { trigger_entity_type, trigger_entity, ...rest } = data;
  switch (trigger_entity_type) {
    case 'event':
      return `${paths.events}/${trigger_entity?.id}`;
    case 'crew_rotation':
      return `${paths.crew_planning}${
        trigger_entity?.vessel_id
          ? `?${stringifyObj({
              filters: [
                { name: 'vessel_id', operation: 'oneOf', value: [trigger_entity.vessel_id] }
              ]
            })}`
          : ''
      }`;

    default:
      return null;
  }
};

export const getTriggerEntityIcon = isForVessel => {
  if (isForVessel) return vesselIcon;
  return companyIcon;
};

export const getCanSubmitForm = ({ data, isOnBoard, account }) => {
  const { is_for_vessel, form_submission, responsible_type, responsible_id } = data;

  if (form_submission?.id) return false;
  if ((is_for_vessel && !isOnBoard) || (!is_for_vessel && isOnBoard)) return false;

  if (responsible_type === 'crew_rank' && account.crew_rank?.id === responsible_id) return true;
  if (
    responsible_type === 'department_role' &&
    account.department_roles?.some(role => role.id === responsible_id)
  )
    return true;
  if (
    responsible_type === 'department' &&
    account.departments?.some(department => department.id === responsible_id)
  )
    return true;

  return false;
};
