import { FC, useMemo, useState } from 'react';
import { components, OptionProps } from 'react-select';
import _isArray from 'lodash/isArray';

import useFilterSelector from 'common/components/filters/useFilterSelector';
import { showSparePartsNumber } from '@/common/utils/form/display';
import { TableFilterSelectorProps } from '@/common/types/front-entities/table';

import Select from '@/ts-common/components/form/inputs/select';
import { SparePartBase } from '@/common/types/pms';

const SparePartsSelector: FC<TableFilterSelectorProps> = ({
  value,
  filter,
  onChange,
  isMulti,
  label,
  placeholder,
  listParams,
  ...rest
}) => {
  const [selectedSpareParts, setSelectedSpareParts] = useState<
    SparePartBase | SparePartBase[] | null
  >([]);

  const selectedValue = filter?.value || value;

  useFilterSelector(
    selectedValue,
    { list: 'spare-parts' },
    { selected: selectedSpareParts, setSelected: setSelectedSpareParts }
  );

  const memoizedRequestParams = useMemo(
    () => ({
      params: { ...(listParams || {}), list: 'spare-parts' },
      path: 'lists'
    }),
    [listParams]
  );

  return (
    <Select
      label={label}
      placeholder={placeholder ? placeholder : 'Select value'}
      getOptionValue={option => option.id}
      getOptionLabel={option => `${option.part_no}. ${option.description}`}
      components={{ Option }}
      value={
        isMulti
          ? selectedValue?.length && Array.isArray(selectedSpareParts)
            ? selectedSpareParts.filter(el => selectedValue.map(Number).includes(el.id))
            : []
          : selectedValue?.value || selectedValue
      }
      onChange={(selected: SparePartBase | SparePartBase[] | null) => {
        setSelectedSpareParts(selected);
        onChange({
          value: selected
            ? isMulti && Array.isArray(selected)
              ? [...selected.map(s => s.id)]
              : selected
            : ''
        });
      }}
      isMulti={isMulti}
      memoizedRequestParams={memoizedRequestParams}
      isAsync
      {...rest}
    />
  );
};

export const Option: FC<OptionProps<SparePartBase>> = ({ ...props }) => {
  return (
    <components.Option {...props}>
      <div className="d-flex w-100p">
        {showSparePartsNumber(props.data.drawing_no) ? (
          <>
            <strong>{props.data.drawing_no}</strong>
            &nbsp;-&nbsp;
          </>
        ) : null}
        {props.data.part_no}
        {'. '}
        {props.data.description}
      </div>
    </components.Option>
  );
};

export default SparePartsSelector;
