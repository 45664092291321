import PropTypes from 'prop-types';

import { CrewDepartment } from 'common/components/selectors/departments/CrewDepartment';
import NamesInTooltip from 'common/components/general/NamesInTooltip';
import EntityLabel from 'common/components/labels/EntityLabel';
import TextWithTooltip from '@/ts-common/components/general/TextWithTooltip';

export const Department = ({ value }) => {
  if (!value) return null;

  if (value?.is_crew_department) {
    return <CrewDepartment name={value.name} key={value.id} />;
  } else {
    return (
      <EntityLabel type="department" color={value?.color}>
        <TextWithTooltip className="h-auto">{value?.name}</TextWithTooltip>
      </EntityLabel>
    );
  }
};

const Departments = ({ values = [], maxLimit = 1 }) => {
  return (
    <div className="departments-wrap">
      <NamesInTooltip
        maxHeight={values?.length > 1 ? 18 : 20}
        names={(values || []).map(value => (
          <Department value={value} key={value?.id} />
        ))}
        maxLimit={maxLimit} // show always the first department to prevent issues with the hiddenNamesCount
        customSeparator=" "
        showThreeDots={false}
      />
    </div>
  );
};

export default Departments;

Departments.propTypes = {
  values: PropTypes.array,
  maxLimit: PropTypes.number
};
