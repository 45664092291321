import { get } from '@/api';
import { UseQueryOptionsRequestParams } from '@/ts-common/components/form/inputs/select/types';

export const getDefaultOptions = <T = unknown>(options?: UseQueryOptionsRequestParams<T>) => {
  if (!options) return Promise.resolve([]);

  const { path, params } = options;

  const requestParams = { ...(params || {}) };

  return get<T[]>(path, requestParams).then(res => res.data);
};

export const loadAsyncOptions = async <T = unknown>(
  options?: UseQueryOptionsRequestParams<T>,
  search?: string
) => {
  if (!options || !search?.length) return Promise.resolve([]);

  const { path, params } = options;

  const requestParams = { ...(params || {}), search: search || undefined };

  const response = await get<T[]>(path, requestParams);

  return options.select ? options.select(response.data) : response.data;
};
