import ticketing from 'assets/svg/sidebar/ticketing.svg';
import purchasing from 'common/assets/svg/common/purchasing.svg';
import events from 'common/assets/svg/common/events.svg';
import daCases from '@/common/assets/svg/voyages/da-items.svg';
import permissions from '@/common/utils/permissions/constants';
import _findKey from 'lodash/findKey';

import { getAuthorizedPermissionSettings } from 'utils/permissions/authorize';
import { isAuthorized } from 'utils/permissions/authorize';
import { Department } from '@/common/types/enums';
import { PermissionType } from '@/common/types/permissions';
import { AccountType } from '@/common/types/account';
import { ConfigKey, permittedFormFields } from './form/invoice-body/config';
import { InvoiceTypeLabel } from '@/common/types/invoices';

type InvoiceCategoryType = {
  [key in InvoiceTypeLabel]: { icon: string; tableLabel: string; fieldKey: string };
};

export const invoiceCategory: InvoiceCategoryType = {
  ticketing: { icon: ticketing, tableLabel: 'accounting_invoices_tickets', fieldKey: 'tickets' },
  purchasing: {
    icon: purchasing,
    tableLabel: 'accounting_invoices_requisition_suppliers',
    fieldKey: 'requisition_suppliers'
  },
  'events-accounts': {
    icon: events,
    tableLabel: 'accounting_invoices_events_accounts',
    fieldKey: 'event_accounts'
  },
  'event-crew-medical-cases': {
    icon: events,
    tableLabel: 'accounting_invoices_events_crew_medical_cases_expenses',
    fieldKey: 'event_crew_medical_cases_expenses'
  },
  'port-da-case-agents': {
    icon: daCases,
    tableLabel: 'accounting_invoices_da_agents',
    fieldKey: 'da_case_agents'
  },
  'port-da-case-agent-items': {
    icon: daCases,
    tableLabel: 'accounting_invoices_da_agent_items',
    fieldKey: 'da_case_items'
  }
};

export const isDepartmentAccessDisabledByPermission = (
  { departments }: { departments: Department[] },
  permission: PermissionType,
  account: AccountType
) => {
  if (isAuthorized(account, [permission])) {
    const permissionDepartments = (getAuthorizedPermissionSettings(account, permission)
      ?.departments || []) as number[];
    const assignedDepartments = departments.map(v => v.id);

    return permissionDepartments.length && assignedDepartments.length
      ? !permissionDepartments.some(dep => assignedDepartments.includes(dep))
      : false;
  } else {
    return true;
  }
};

export const isActionDisabledByPermissions = (
  { departments, activeID }: { departments: Department[]; activeID: number | null },
  fieldKey: ConfigKey,
  account: AccountType
) => {
  if (!activeID) {
    // When in create mode, check only for the CREATE permission
    return !isAuthorized(account, [permissions.office.accounting.invoices.create]);
  }

  const fieldPermission = _findKey(permittedFormFields, keys => keys.includes(fieldKey));

  return fieldPermission
    ? isDepartmentAccessDisabledByPermission({ departments }, fieldPermission, account)
    : true;
};
