import { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectChannelPrefix, selectNotificationChannel } from 'store/sockets/selectors';
import { selectOrganizationDomain } from 'store/organization/selectors';
import { selectAccountId } from 'store/account/selectors';
import { selectIsAuthenticated } from 'store/auth/selectors';

import {
  getNotificationsCount,
  receiveSocketNotification,
  getNotifications
} from 'store/notifications/actions';
import { getSocketInfo } from 'store/sockets/actions';
import { receiveSocketTask } from 'store/tasks/slice';

import { io } from 'socket.io-client';

const useSockets = () => {
  const channel = useSelector(selectChannelPrefix);
  const notificationChannel = useSelector(selectNotificationChannel);

  const organizationDomain = useSelector(selectOrganizationDomain);
  const userId = useSelector(selectAccountId);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const dispatch = useDispatch();

  const handleRealData = useCallback(
    realtimeData => {
      if (realtimeData?.event.includes('Notifications') || !realtimeData?.event) {
        dispatch(receiveSocketNotification(realtimeData.data));
      } else {
        dispatch(receiveSocketTask(realtimeData));
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getSocketInfo());
      dispatch(getNotificationsCount());
      dispatch(getNotifications({ current_page: 1, per_page: 50 }));
    }
  }, [isAuthenticated, dispatch]);

  useEffect(() => {
    let socket = null;

    if (channel && notificationChannel && organizationDomain && isAuthenticated && userId) {
      const token = window.localStorage.getItem('accessToken');
      const apiUrl = import.meta.env.VITE_SOCKET_API_URL;

      socket = io(apiUrl, {
        reconnectionDelayMax: 10000,
        reconnectionAttempts: 3,
        path: '/realtime',
        transports: ['polling', 'websocket'],
        extraHeaders: { Authorization: 'Bearer ' + token }
      });

      socket.on(`private-${channel}.${notificationChannel}.${userId}`, notification =>
        handleRealData(notification)
      );
    }

    return () => {
      if (socket) socket.disconnect();
    };
  }, [
    channel,
    notificationChannel,
    organizationDomain,
    isAuthenticated,
    userId,
    handleRealData,
    receiveSocketNotification
  ]);
};
export default useSockets;
