import moment from 'moment';

import NationalitySelector from 'common/components/selectors/NationalitySelector';
import CrewRankSelector from 'common/components/selectors/CrewRankSelector';
import crewPlanning from 'common/assets/svg/common/crew_planning.svg';
import TopFilters from './TopFilters';

const getColumns = () => [
  {
    header: 'Name',
    key: 'full_name',
    type: 'string',
    minWidth: 300,
    maxWidth: 300,
    sort: true
  },
  {
    header: '',
    key: 'promotion_label',
    minWidth: 14,
    maxWidth: 14
  },
  {
    header: 'Rank',
    key: 'rank_id',
    type: 'collection',
    className: 'text-truncate',
    component: CrewRankSelector,
    componentRest: { isMulti: true },
    minWidth: 144.6,
    maxWidth: 144.6,
    sort: true
  },
  {
    header: 'Nationality',
    key: 'nationality_id',
    type: 'collection',
    sort: true,
    component: NationalitySelector,
    componentRest: { isMulti: true, showDefaultOptions: true },
    minWidth: 150,
    maxWidth: 150
  },
  {
    header: 'Place & Date of Birth',
    key: 'birthday',
    type: 'string',
    minWidth: 300,
    maxWidth: 300,
    sort: true,
    canFilter: false
  },
  {
    header: 'SIGN ON DATE & PORT',
    key: 'initial_contract_sign_on',
    type: 'date',
    canFilter: false,
    sort: true,
    minWidth: 300,
    maxWidth: 300
  },
  {
    header: 'Req Sign Off Date',
    key: 'requested_sign_off_date',
    type: 'date',
    canFilter: false,
    sort: true,
    width: 1
  },
  {
    header: 'SIGN OFF DATE & PORT',
    key: 'active_contract_sign_off',
    type: 'date',
    canFilter: false,
    sort: true,
    minWidth: 250,
    maxWidth: 250
  },
  {
    header: 'PASSPORT NO.',
    key: 'passport_no',
    type: 'string',
    canFilter: false,
    sort: false,
    minWidth: 180,
    maxWidth: 180
  },
  {
    header: 'PASSPORT EXP.DATE',
    key: 'passport_exp_date',
    type: 'date',
    canFilter: false,
    sort: false,
    minWidth: 180,
    maxWidth: 180
  },
  {
    header: 'USA VISA NO.',
    key: 'usa_visa_no',
    type: 'string',
    canFilter: false,
    sort: false,
    minWidth: 180,
    maxWidth: 180
  },
  {
    header: 'USA VISA EXP.DATE',
    key: 'usa_visa_exp_date',
    type: 'date',
    canFilter: false,
    sort: false,
    minWidth: 180,
    maxWidth: 180
  },
  {
    header: 'Actions',
    key: 'actions',
    field: 'actions'
  }
];

export const getTopFilters = () => [
  {
    name: 'onboard_as_of_date',
    operation: '=',
    value: moment().format('YYYY-MM-DD')
  }
];

const config = {
  getColumns,
  getTopFilters,
  topFiltersComponent: TopFilters,
  icon: crewPlanning,
  hideTableFilters: false,
  isModular: false,
  pageTitleComponent: null,
  name: 'Crew',
  entity: 'crew'
};

export default config;
