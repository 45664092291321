import { Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';
import {
  selectItemBasicField,
  selectItemSupplierDetailsField
} from 'common/components/purchasing/requisition/store/selectors';
import {
  selectHasSelectedRequisitionSuppliers,
  selectRequisitionSupplier,
  getSupplierTypeByPrice,
  selectActiveRequisitionSupplierIds
} from 'store/purchasing/requisition-summary/selectors';
import CodeDescription from 'common/components/purchasing/requisition/categories/items/fields/CodeDescription';
import { numberToStr } from 'common/utils/numbers';
import arrow from 'common/assets/svg/jobs/priority_arrow.svg';
import SvgRender from 'common/components/general/SvgRender';
import styled from '@emotion/styled';
import { arrowChangeQuantityDirection } from 'views/purchasing/requisitions/requisition/summary/categories/items/helpers';
import PropTypes from 'prop-types';

const SupplierDiscount = ({ itemID, supplierId }) => {
  const fieldKey = 'discount_percentage';
  const value = useSelector(state =>
    selectItemSupplierDetailsField(state, itemID, fieldKey, supplierId)
  );

  return <strong>{numberToStr(value, 2, 2)}</strong>;
};

SupplierDiscount.propTypes = {
  itemID: PropTypes.number,
  supplierId: PropTypes.number
};

const SupplierQuantity = ({ itemID, supplierId }) => {
  const supplier = useSelector(state => selectRequisitionSupplier(state, supplierId));
  const type = getSupplierTypeByPrice(supplier);

  const fieldKey = !type ? 'available_quantity' : `${type}_quantity`;
  const value = useSelector(state =>
    selectItemSupplierDetailsField(state, itemID, fieldKey, supplierId)
  );

  return <strong>{numberToStr(value)}</strong>;
};

SupplierQuantity.propTypes = {
  itemID: PropTypes.number,
  supplierId: PropTypes.number
};

const SupplierPrice = ({ itemID, supplierId }) => {
  const supplier = useSelector(state => selectRequisitionSupplier(state, supplierId));
  const type = getSupplierTypeByPrice(supplier);

  const fieldKey = !type
    ? 'total_discounted_price_base_currency_equivalent'
    : `total_${type}_price_base_currency_equivalent`;
  const value = useSelector(state =>
    selectItemSupplierDetailsField(state, itemID, fieldKey, supplierId)
  );

  return <strong>{numberToStr(value, 2, 2)}</strong>;
};

SupplierPrice.propTypes = {
  itemID: PropTypes.number,
  supplierId: PropTypes.number
};

const QuantityChangeArrow = ({ itemID, supplierId }) => {
  const increasedQuantity = useSelector(state =>
    selectItemSupplierDetailsField(state, itemID, 'increased_quantity', supplierId)
  );

  const direction = arrowChangeQuantityDirection(increasedQuantity);

  return (
    <div className="ms-1">
      {direction === 'up' ? (
        <div className="d-flex">
          <SvgRender src={arrow} style={{ width: 11, height: 11 }} className="text-turquoise" />
        </div>
      ) : direction === 'down' ? (
        <ArrowReversedContainer className="d-flex">
          <SvgRender src={arrow} style={{ width: 11, height: 11 }} className="text-red" />
        </ArrowReversedContainer>
      ) : null}
    </div>
  );
};

QuantityChangeArrow.propTypes = {
  itemID: PropTypes.number,
  supplierId: PropTypes.number
};

const Item = ({ itemID, index, categoryID }) => {
  const code = useSelector(state => selectItemBasicField(state, itemID, 'code'));
  const hasSelectedRequisitionSuppliers = useSelector(selectHasSelectedRequisitionSuppliers);
  const activeRequisitionSupplierIds = useSelector(selectActiveRequisitionSupplierIds);

  const itemWitdth = activeRequisitionSupplierIds.length === 1 ? 8 : 5;

  return (
    <Row
      className="text-primary fs-12 cmb-4 purchasing-requisition__items-single min-height-24 ps-1"
      noGutters
    >
      <Col
        xs={hasSelectedRequisitionSuppliers ? itemWitdth : 12}
        className="cme-4 bg-light-gray rounded rounded-sm ps-3 cpy-4 cpe-4 position-relative"
        data-index={index + 1}
      >
        <CodeDescription itemID={itemID} value={code} categoryID={categoryID} hideColor />
      </Col>
      {hasSelectedRequisitionSuppliers &&
        activeRequisitionSupplierIds.map(supplierId => (
          <Col className="cps-2 cpe-2 d-flex align-items-center" key={supplierId}>
            <Col className="bg-light-gray rounded-start rounded-sm cpe-12 cpy-4 justify-content-end d-flex align-items-center">
              <SupplierQuantity itemID={itemID} supplierId={supplierId} />
              <QuantityChangeArrow itemID={itemID} supplierId={supplierId} />
            </Col>

            <Col className="bg-light-gray rounded-start rounded-sm cpe-12 cpy-4 text-end">
              <SupplierDiscount itemID={itemID} supplierId={supplierId} />
            </Col>

            <Col className="bg-light-gray rounded-end rounded-sm cpe-12 cpy-4 text-end">
              <SupplierPrice itemID={itemID} supplierId={supplierId} />
            </Col>
          </Col>
        ))}
    </Row>
  );
};

export default Item;

Item.propTypes = {
  itemID: PropTypes.number,
  index: PropTypes.number,
  categoryID: PropTypes.number
};

const ArrowReversedContainer = styled.div`
  transform: rotate(180deg);
`;
