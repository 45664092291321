import FormsSelector from 'common/components/selectors/FormsSelector';
import CrewRankSelector from 'common/components/selectors/CrewRankSelector';
import PersonSelector from 'common/components/selectors/PersonSelector';
import DepartmentsFilterSelector from 'common/components/selectors/departments/DepartmentSelector';
import FormStatusSelector from 'common/components/selectors/FormStatusSelector';
import ImportanceSelector from 'common/components/selectors/ImportanceSelector';
import TrainingTypeSelector from 'common/components/selectors/TrainingTypeSelector';
import DrillsSelector from 'common/components/selectors/DrillsSelector';
import formsIcon from '@/common/assets/svg/common/forms.svg';

const getColumns = () => [
  {
    header: 'NO.',
    key: 'orca_id',
    sort: true,
    canFilter: false,
    minWidth: 100,
    maxWidth: 100,
    hiddenOnBoard: true
  },
  {
    header: 'I',
    filterLabel: 'Importance',
    key: 'importance',
    type: 'collection',
    sort: true,
    hidden: false,
    component: ImportanceSelector,
    componentRest: { isMulti: true, styled: false },
    canFilter: true,
    left: 0,
    minWidth: 64,
    maxWidth: 64
  },
  {
    header: 'Form',
    key: 'form',
    type: 'collection',
    sort: true,
    canFilter: true,
    minWidth: 518,
    maxWidth: 518,
    component: FormsSelector,
    componentRest: { isMulti: true }
  },
  {
    header: 'Type',
    key: 'type',
    type: 'string',
    canFilter: false,
    sort: true,
    minWidth: 300,
    maxWidth: 300,
    width: 4
  },
  {
    header: 'Drills',
    key: 'drill',
    type: 'collection',
    hidden: true,
    sort: false,
    className: 'd-none',
    component: DrillsSelector,
    canFilter: true,
    componentRest: { isMulti: true }
  },
  {
    header: 'Training',
    key: 'training',
    sort: false,
    canFilter: true,
    className: 'd-none',
    component: TrainingTypeSelector,
    type: 'collection',
    componentRest: { isMulti: true }
  },
  {
    header: 'Status',
    key: 'status',
    type: 'collection',
    sort: true,
    canFilter: true,
    minWidth: 200,
    maxWidth: 200,
    width: 2,
    component: FormStatusSelector,
    componentRest: { isMulti: true },
    className: 'ps-4'
  },
  {
    header: 'Created by',
    key: 'submitted_by',
    type: 'collection',
    hidden: true,
    sort: false,
    className: 'd-none',
    component: PersonSelector,
    canFilter: true,
    componentRest: { isMulti: true, listParams: { onboard: true } },
    width: 2
  },
  {
    header: 'Created at',
    key: 'created_at',
    type: 'date',
    hidden: true,
    sort: false,
    showUtc: true,
    className: 'd-none',
    canFilter: true,
    width: 2
  },
  {
    header: 'Department',
    key: 'department',
    canFilter: true,
    type: 'collection',
    sort: true,
    minWidth: 200,
    maxWidth: 200,
    width: 2,
    component: DepartmentsFilterSelector
  },
  {
    header: 'Form Date',
    key: 'submission_date',
    type: 'date',
    width: 'auto',
    sort: true,
    showUtc: true,
    canFilter: true
  },
  {
    header: 'Resubmitted',
    key: 'resubmitted',
    type: 'boolean',
    hidden: true,
    options: [
      { value: 'true', label: 'Yes' },
      { value: 'false', label: 'No' }
    ]
  },
  {
    header: 'With Remarks for Vessel',
    key: 'with_remarks_for_vessel',
    type: 'boolean',
    hidden: true,
    options: [
      { value: 'true', label: 'Yes' },
      { value: 'false', label: 'No' }
    ]
  },
  {
    header: 'With Actionable Remarks for Vessel',
    key: 'for_correction_remarks_actionable',
    type: 'boolean',
    hidden: true,
    options: [
      { value: 'true', label: 'Yes' },
      { value: 'false', label: 'No' }
    ]
  },
  {
    header: 'Signature Pending By Rank',
    key: 'pending_signature_requirement_for_crew_rank_ids',
    canFilter: true,
    type: 'collection',
    hidden: true,
    componentRest: { isMulti: true },
    component: CrewRankSelector
  },
  {
    header: 'Chat',
    key: 'chat',
    type: 'chat',
    canFilter: true,
    hidden: true,
    options: [
      { label: 'No Messages', value: 'no_messages' },
      { label: 'Unread Messages', value: 'unread_messages' },
      { label: 'Messages', value: 'messages' }
    ]
  },
  {
    header: 'Actions',
    key: 'actions',
    field: 'actions'
  }
];

const config = {
  getColumns,
  getTopFilters: () => null,
  topFiltersComponent: () => null,
  isModular: false,
  hideTableFilters: false,
  icon: formsIcon,
  pageTitleComponent: (
    <div className="d-flex align-items-center">
      Forms <div className="fw-bold ms-1">/ List</div>
    </div>
  ),
  name: 'Form Submissions',
  entity: 'forms'
};

export default config;
