import { FC, useState } from 'react';

import useTableTopFilterValue from 'common/components/filters/useTableTopFilterValue';
import { getTableVisibilityParams } from 'common/components/table/utils/helpers';
import AsOfDateFilter from 'common/components/dates/AsOfDateFilter';
import ExportIcon from 'common/components/general/ExportIcon';
import TableTopFilter from 'common/components/table/TableTopFilter';
import ExportSelector, { OptionsType } from '@/common/components/selectors/ExportSelector.tsx';
import { handleFileDownload } from 'common/utils/downloads';
import { download } from 'utils/api';
import { borderedFilesTypes } from '@/ts-common/utils/files.ts';

import checkIcon from 'common/assets/svg/common/check-circle.svg';
import list from 'common/assets/svg/common/list.svg';
import camera from 'common/assets/svg/common/camera-line.svg';
import vessel from 'common/assets/svg/common/vessels.svg';
import { useAppSelector } from '@/store/hooks';
import {
  TableProps,
  TableTopFilterType,
  TableSetTopFilterFunc
} from '@/common/types/front-entities/table.ts';

const options = [
  {
    label: 'Default',
    value: 'default',
    types: [{ type: 'excel', extension: borderedFilesTypes.excel, text: 'Export excel' }],
    icon: checkIcon
  },
  {
    label: 'Immigration Crew List',
    value: 'immigration',
    types: [{ type: 'excel', extension: borderedFilesTypes.excel, text: 'Export excel' }],
    icon: list
  },
  {
    label: 'Immigration Photo Crew List',
    value: 'photos',
    types: [{ type: 'pdf', extension: borderedFilesTypes.pdf, text: 'Export pdf' }],
    icon: camera
  },
  {
    label: 'IMO Crew List',
    value: 'imo',
    types: [
      { type: 'excel', extension: borderedFilesTypes.excel, text: 'Export excel' },
      { type: 'pdf', extension: borderedFilesTypes.pdf, text: 'Export pdf' }
    ],
    icon: vessel
  }
];

type TopFiltersComponentProps = {
  topFilters: TableTopFilterType[];
  setTopFilters: TableSetTopFilterFunc;
  table?: TableProps;
};

const TopFilters: FC<TopFiltersComponentProps> = ({ topFilters, setTopFilters, table }) => {
  const [selected, setSelected] = useState<OptionsType | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const isOnBoard = useAppSelector(state => state.isOnBoard);
  const [dateFilter, setDateFilter] = useTableTopFilterValue(
    { topFilters: topFilters, setTopFilters: setTopFilters },
    'onboard_as_of_date'
  );

  const onDownload = async (extraParams: Record<string, unknown>) => {
    if (!table || !table.requestParams) return null;

    const { sorting, filters } = table.requestParams;

    const rParams = {
      sorting,
      filters,
      visible: getTableVisibilityParams(table),
      ...extraParams
    };

    setIsLoading(true);

    try {
      await handleFileDownload(
        {
          url: '/crew/export'
        },
        download,
        false,
        {
          requestParams: rParams,
          parsed: true
        }
      );

      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    }
  };

  return (
    <div className="d-flex align-items-center">
      <AsOfDateFilter
        onChange={(value: string) => setDateFilter(value || null)}
        value={dateFilter}
      />

      {isOnBoard ? (
        <>
          <div className="small-seperator cpt-12 bg-platinum cpb-12 mx-2"></div>

          <TableTopFilter>
            <ExportSelector
              className="me-1"
              selected={selected}
              setSelected={setSelected}
              options={options}
            />
          </TableTopFilter>
        </>
      ) : null}

      {selected
        ? selected.types.map((s, i) => (
            <ExportIcon
              key={i}
              extension={s.extension}
              text={s.text}
              onClick={() => onDownload({ export: selected.value, type: s.type })}
              className={`${i >= 1 ? 'ms-1' : ''}`}
              disabled={isLoading}
            />
          ))
        : null}
    </div>
  );
};

export default TopFilters;
